<template>
  <section class="edit-category">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Edit Role</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/roles/roles" class="text-primary"
              >Role
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Edit Role</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-if="newRole">
            <form @submit.prevent="editRole">
              <div class="mb-1">
                <!-- <label class="border-order-label">Edit Role</label> -->
                <div  >
                  <div class="pt-1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <h4>Role: {{newRole.role}}</h4>
                              <br>
                              <h4>Type: {{this.$route.query.type == 'cms'? 'CMS' : 'Client'}}</h4>
                            </div>
                            <br>
                          </div>
                          <div v-if="this.$route.query.type == 'cms'" class="col-12">
                            <div class="form-group">
                              <label for="name">CMS Permissions</label>
                              <multiselect
                                v-model="newRole.cms_permissions"
                                :multiple="true" label="name"
                                track-by="name"
                                :options="cmsPerm"
                              ></multiselect>
                            </div>
                          </div>
                          <div v-if="this.$route.query.type == 'client'" class="col-12">
                            <div class="form-group">
                              <label for="name">Client Permissions</label>
                              <multiselect
                                v-model="newRole.permissions"
                                :multiple="true"
                                :options="clientPerm"
                              ></multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                     

                      <div class="col-12 pt-1">
                        <button class="btn btn-primary btn-md btn-block">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { roleApi } from "../../api";
import Multiselect from "vue-multiselect";
import Loader from "../../components/Loader";
export default {
  name: "edit-role",
  components: {
    Loader,Multiselect
  },
  data() {
    return {
      selectRole: null,
      showLoader: false,
      newRole: {
        permissions: [],
      },
      perm_empty: [],
      cmsPerm: [],
      clientPerm: [],
      type: ''
    };
  },
  mounted() {
    this.getCmsPermissions();
    this.getClientPermissions();
    if(this.$route.query.type == 'cms') {
      this.getRoles()
      this.type = 'cms'
    } else {
      this.getRolesClient()
      this.type = 'client'
    }
    console.log(this.type)
  },
  methods: {
    async getRoles() {
      this.submitted = true;
      this.showLoader = true;
      const { data } = await roleApi.getRoles(this.$route.params.role, 'cms');
      this.newRole = data.data
      console.log(this.newRole.cms_permissions
,'newRole');
      this.exciting = data.data
      this.showLoader = false;
    },
    async getRolesClient() {
      this.submitted = true;
      this.showLoader = true;
      const { data } = await roleApi.getRoles(this.$route.params.role, 'client');
      this.newRole = data.data
      this.exciting = data.data
      this.showLoader = false;
    },
    async getCmsPermissions() {
      this.submitted = true;

      this.showLoader = true;
      const { data } = await roleApi.getPermissions();
      this.cmsPerm = data.data.data.cms_permissions
      this.showLoader = false;
    },
    async getClientPermissions() {
      this.submitted = true;

      this.showLoader = true;
      const { data } = await roleApi.getPermissions();
      this.clientPerm = data.data.data.client_permissions
      this.showLoader = false;
    },
    async editRole() {
      this.perm_empty = [];
      this.submitted = true;

      for (var key in this.user) {
        if (this.user.hasOwnProperty(key)) {
          var val = this.user[key];
          if (val === null) {
            delete this.user[key];
          }
        }
      }

      if (this.permissions) {
        this.permissions.forEach((value) => {
          let permissions = value.id;
          if (!this.perm_empty.includes(permissions)) this.perm_empty.push(permissions);
        });
        this.newRole.permissions = this.perm_empty;
      }

      this.showLoader = true;
      const data = await roleApi.editRole(this.$route.params.role, this.newRole.permissions, this.type);
      this.showLoader = false;
      this.$router.push("/roles/roles");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style scoped>
.border-order-label {
  background: #fff;
  margin-left: 22px;
  padding: 0px 9px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
}

.form-group label {
  font-size: 12px;
}

</style>